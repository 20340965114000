import { ResourceKey } from "i18next";

export const SearchFilterTags: ResourceKey = {
  RESET_FILTERS: "Wis alle filters",
  FIELD_GROUP_TITLE: "Zoeken in: Titel",
  FIELD_GROUP_DESCRIPTION: "Zoeken in: Beschrijving",
  FIELD_GROUP_SPECIFICATIONS: "Zoeken in: Specificaties",
  FIELD_GROUP_ADDENDA: "zoek in bijlagen: JA",
  FIELD_GROUP_AWARD_COMPANY_NAMES: "Zoek in winnaars: JA",
  FIELD_GROUP_BID_COMPANY_NAMES: "Zoek in deelnemers: JA",

  PUBLICATION_DATE_EVERYTHING: "",
  PUBLICATION_DATE_YESTERDAY: "",
  PUBLICATION_DATE_LAST_WEEK: "",
  PUBLICATION_DATE_LAST_MONTH: "",
  PUBLICATION_DATE_LAST_TWO_MONTHS: "",
  PUBLICATION_DATE_LAST_YEAR: "",
  PUBLICATION_DATE_CUSTOM: "",

  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM: "",

  DEADLINE_NOT_YET_EXPIRED: "",
  DEADLINE_EVERYTHING: "",
  DEADLINE_IN_7_DAYS: "",
  DEADLINE_IN_14_DAYS: "",
  DEADLINE_IN_1_MONTH: "",
  DEADLINE_IN_2_MONTHS: "",
  DEADLINE_CUSTOM: "",
  DEADLINE_THIS_YEAR: "",
  DEADLINE_PREVIOUS_YEAR: "",
  DEADLINE_TWO_YEARS_AGO: "",
  COUNTRY_PREFIX: "Land:",
  LANGUAGE_PREFIX: "Taal:",
  HAS_PRIOR_INFORMATION_NOTICE: "Formuliertype: Vooraankondiging",
  HAS_CONTRACT_NOTICE: "Formuliertype: Aankondiging",
  HAS_CONTRACT_AWARD_NOTICE: "Formuliertype: Gunning",
  HAS_DESIGN_CONTEST: "Formuliertype: Ontwerpwedstrijd",
  HAS_CORRIGENDA: "Formuliertype: Rectificatie",
  HAS_AWARD_MODIFICATIONS: "Formuliertype: Rectificatie van gunning",
  HAS_OPENING_REPORT: "Formuliertype: Rapport van opening",
  HAS_MUNICIPAL_DECISION: "Formuliertype: Gemeenteraadsverslag",
  HAS_ADDENDA: "Bijlagen: ja",
  HAS_AWARDS: "Gegund: ja",

  DOES_NOT_HAVE_PRIOR_INFORMATION_NOTICE: "",
  DOES_NOT_HAVE_CONTRACT_NOTICE: "",
  DOES_NOT_HAVE_CONTRACT_AWARD_NOTICE: "",
  DOES_NOT_HAVE_DESIGN_CONTEST: "",
  DOES_NOT_HAVE_CORRIGENDA: "",
  DOES_NOT_HAVE_AWARD_MODIFICATIONS: "",
  DOES_NOT_HAVE_OPENING_REPORT: "",
  DOES_NOT_HAVE_ADDENDA: "Bijlagen: nee",
  CONTRACT_TYPE_SERVICES: "Soort opdracht: Diensten",
  CONTRACT_TYPE_WORKS: "Soort opdracht: Werken",
  CONTRACT_TYPE_SUPPLIES: "Soort opdracht: Leveringen",
  CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION: "Aanbestedende overheid: Internationale organisatie",
  CONTRACTING_AUTHORITY_TYPE_MINISTRY: "Aanbestedende overheid: Ministerie",
  CONTRACTING_AUTHORITY_TYPE_UTILITIES: "Aanbestedende overheid: Nutsbedrijven",
  CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC: "Aanbestedende overheid: Publiekrechtelijke instelling",
  CONTRACTING_AUTHORITY_TYPE_RA: "Aanbestedende overheid: Regionale instantie",
  CONTRACTING_AUTHORITY_TYPE_LA: "Aanbestedende overheid: Lokale overheid",
  CONTRACTING_AUTHORITY_TYPE_CGA: "Aanbestedende overheid: Centrale overheidsinstantie",
  CONTRACTING_AUTHORITY_TYPE_DEF_CONT: "Aanbestedende overheid: Contractant op defensiegebied",
  CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT: "Aanbestedende overheid: Overheidsonderneming",
  CONTRACTING_AUTHORITY_TYPE_REGIONAL_AGENCY: "Aanbestedende overheid: Regionaal orgaan",
  CONTRACTING_AUTHORITY_TYPE_NATIONAL_AGENCY: "Aanbestedende overheid: Nationaal agentschap",
  CONTRACTING_AUTHORITY_TYPE_OTHER: "Aanbestedende overheid: Andere",
  PUBLICATION_RADIUS_EUROPEAN_UNION: "Europese publicatie: Ja",
  PUBLICATION_RADIUS_NATIONAL: "Europese publicatie: Nee",

  TENDER_UUID_FILTER_LEADS: "",
  TENDER_UUID_FILTER_FOLLOWED_TENDERS: "",
  TENDER_UUID_FILTER_OPPORTUNITIES: "",
  PROCEDURE_TYPE_OPEN: "Procedure: Openbaar",
  PROCEDURE_TYPE_RESTRICTED: "Procedure: Beperkt",
  PROCEDURE_TYPE_NEGOTIATION: "Procedure: Onderhandeling",
  PROCEDURE_TYPE_COMPETITIVE_DIALOGUE: "Procedure: Concurrentiegerichte dialoog",
  PROCEDURE_TYPE_INNOVATION_PARTNERSHIP: "Procedure: Innovatiepartnerschap",
  PROCEDURE_TYPE_OTHER: "Procedure: Andere",

  IS_FRAMEWORK_AGREEMENT_YES: "Raamcontracten",
  IS_FRAMEWORK_AGREEMENT_NO: "Geen raamcontracten",
  IS_SHELTERED_WORKSHOP: "Enkel maatwerkbedrijven",
  IS_SHELTERED_PROGRAM: "Programma’s voor beschermde arbeid",

  until: "t.e.m.",
  label: "Label",
  notYetExpired: "nog niet verstreken",
  limited_results_message_part_one: "Je huidige prijsplan laat het toe om slechts ",
  limited_results_message_part_two: " resultaten te raadplegen. Upgrade je plan om de overige resultaten te kunnen bekijken en doorzoeken.",
  hints_date_message_part_one: "Je zoekt nu binnen de periode van ",
  hints_date_message_part_two: " tot ",
  hints_date_message_part_three: ". Pas de datum aan in de zijbalk om breder te zoeken.",
  trial_message_part_one: "Je instellingen zijn nog niet geoptimaliseerd door een van onze experten. ",
  trial_message_part_two: "Neem contact op",
  trial_message_part_three: " voor een gratis finetuning ",
  trial_message_part_four: "of neem alvast een kijkje op ",
  trial_message_part_five: "Starten met TenderWolf",
  search: "Zoeken",
  similarTenderUuid: "Gelijkaardig aan"
};
