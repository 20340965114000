import React, {useEffect, useRef, useState} from "react";

import "./profile-settings.scss";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {useTranslation} from "react-i18next";
import {SavedSearchDetails} from "../../savedSearchSettings";
import {resetSearch, TenderSearch} from "../../../hooks/slices/tenderSearchSlice";
import {GearIcon, TrashIcon} from "../../../components/icons";
import {showErrorPopup, updateSnacc} from "../../../hooks/slices/snaccSlice";
import {Feature} from "../../../consts/features";
import {ErrorPage} from "../../error";
import {RaiseCurrentPlan} from "../../../hooks/raiseCurrentPlan";
import {CodeUtil, CpvUtil} from "../../../utils/codes";
import {FeatureUtil} from "../../../utils/features";
import {ConfirmPopup} from "../../../components/popup/Popup";
import {Loader} from "../../../components/loader";
import {TenderSearchUtil} from "../../../utils/savedsearches";
import {AccountSettingsFormSkeleton} from "../accountSettingsFormSkeleton";
import {CodeBranch} from "../../../types/code";
import {RaiseSavedSearches} from "../../../hooks/raiseSavedSearches";
import {
  addTenderSearchToState,
  deleteTenderSearchFromState,
  TenderSearchesState,
  updateIndividualTenderSearchState,
  useDeleteSavedSearchMutation,
  usePostSavedSearchMutation,
  usePutSavedSearchMutation,
} from "../../../hooks/slices/savedSearchesSlice";
import {Language} from "../../../consts/languages";
import {UserLanguage} from "../../../hooks/raiseUser";
import {useSearchParams} from "react-router-dom";
import {SearchFilterUtil} from "../../../utils/searchFilters";

export const SavedSearchesTab: React.FC = () => {
  const userUuid = useAppSelector((state) => state.user.uuid);
  const savedSearchesInStore = RaiseSavedSearches();
  if (savedSearchesInStore.isLoading) return <AccountSettingsFormSkeleton inputElements={3} />;
  else if (savedSearchesInStore.error) return <ErrorPage error={savedSearchesInStore.error} />;
  else if (savedSearchesInStore.savedSearches.tenderSearches && userUuid) {
    return <SavedSearchSettings userUuid={userUuid} savedSearchesState={savedSearchesInStore.savedSearches} />;
  } else return <AccountSettingsFormSkeleton inputElements={3} />;
};

export const SavedSearchSettings: React.FC<{ userUuid: string; savedSearchesState: TenderSearchesState }> = ({
  userUuid,
  savedSearchesState,
}) => {
  const { t, i18n } = useTranslation();
  const language: Language = UserLanguage(i18n.language);
  const dispatch = useAppDispatch();
  const [curatedSavedSearches, setCuratedSavedSearches] = useState<TenderSearch[]>(
    savedSearchesState.curatedTenderSearches
      ? TenderSearchUtil.createCuratedTenderSearchDummies(savedSearchesState.curatedTenderSearches, language)
      : []
  );
  const [savedSearches, setSavedSearches] = useState<TenderSearch[]>(
    savedSearchesState.tenderSearches ? savedSearchesState.tenderSearches : []
  );
  const [showLoader, setShowLoader] = useState(false);
  const [postSavedSearchToBackend, { data: savedSearchesPlusOne, isError: plusIsError, error: plusError }] =
    usePostSavedSearchMutation();
  const [deleteSavedSearchFromBackend, { isError: minusIsError, error: minusError, isSuccess: deleteSuccess }] =
    useDeleteSavedSearchMutation();
  useEffect(() => {
    if (plusIsError && plusError) dispatch(showErrorPopup(plusError));
    if (minusIsError && minusError) dispatch(showErrorPopup(minusError));
  }, [plusIsError, plusError, minusIsError, minusError]);
  //features are always fetched by the navbar
  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  const addNewSavedSearch = () => {
    if (features) {
      let countries = FeatureUtil.getCountriesFromPublisherFeatures(features);
      let savedSearch: TenderSearch = getNewSavedSearch(countries, t("savedSearchSettings.defaultName"), userUuid);
      postSavedSearchToBackend(savedSearch);
      setShowLoader(true);
    }
  };

  const deleteSavedSearch = (savedSearch: TenderSearch) => {
    deleteSavedSearchFromBackend(savedSearch);
    dispatch(deleteTenderSearchFromState(savedSearch));
    let isCurated = savedSearch.curatedProfileId != null;
    if (isCurated){
      setCuratedSavedSearches(curatedSavedSearches.filter((s) => s.curatedProfileId !== savedSearch.curatedProfileId));
    } else setSavedSearches(savedSearches.filter(s => s.uuid !== savedSearch.uuid));
    setShowLoader(true);
  };

  useEffect(() => {
    if (savedSearchesPlusOne !== undefined) {
      setSavedSearches([...savedSearches, savedSearchesPlusOne]);
      dispatch(addTenderSearchToState(savedSearchesPlusOne));
      setShowLoader(false);
    }
  }, [savedSearchesPlusOne]);
  useEffect(() => {
    if (deleteSuccess) {
      setShowLoader(false); //state has already been updated
    }
  }, [deleteSuccess]);
  return (
    <div>
      {showLoader && <Loader />}
      <div className="profiles-section-alignment">
        <div className="page-alignment ">
          <div>
            <h1>{t("settings.profiles")}</h1>
          </div>
        </div>
        {curatedSavedSearches.map((p, i) => (
          <SavedSearchCard
            savedSearch={p}
            key={p.uuid ? p.uuid : i}
            deleteSavedSearch={deleteSavedSearch}
            userUuid={userUuid}
            curatedProfile={true}
          />
        ))}
        {savedSearches.map((p, i) => (
          <SavedSearchCard
            savedSearch={p}
            key={p.uuid ? p.uuid : i}
            deleteSavedSearch={deleteSavedSearch}
            userUuid={userUuid}
            curatedProfile={false}
          />
        ))}
        <div className="fill-button">
          {FeatureUtil.userHasExtraSavedSearchAvailable(savedSearches, features) && (
            <button onClick={() => addNewSavedSearch()} data-testid={"add-user-profile-button"}>
              {t("savedSearchSettings.addProfile")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

function getNewSavedSearch(countries: string[], name: string, userUuid: string): TenderSearch {
  return {
    name: name,
    countries: countries,
    userUuid: userUuid,
  };
}

interface SavedSearchCardProps {
  userUuid: string;
  savedSearch: TenderSearch;
  curatedProfile: boolean;
  deleteSavedSearch: (savedSearch: TenderSearch) => void;
}

const SavedSearchCard: React.FC<SavedSearchCardProps> = ({
  userUuid,
  savedSearch,
  deleteSavedSearch,
  curatedProfile,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // Create a reference to the SavedSearchCard component
  const cardRef = useRef<HTMLDivElement>(null);
  const [updateSavedSearchBackend, { data: updatedSavedSearch, isLoading, isSuccess, isError, error }] =
    usePutSavedSearchMutation();
  //todo get search params here
  const [searchParams] = useSearchParams();
  const [current, setCurrent] = useState(savedSearch);
  const canBeEdited = !curatedProfile;
  const [editModeOn, setEditModeOn] = useState<boolean>(canBeEdited && searchParams.get("profile") === savedSearch.name);
  const undefinedIfFalse = (b: boolean): boolean | undefined => {
    if (b) return b;
    else return undefined;
  }
  useEffect(() => {
    if (editModeOn && cardRef.current) {
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
    }
  }, [editModeOn]);
  const updateSavedSearch = (nuw: TenderSearch) => {
    let updated: TenderSearch = {
      uuid: current.uuid,
      userUuid: userUuid,
      isActive: current.isActive,
      languageIsos: nuw.languageIsos ? nuw.languageIsos : current.languageIsos,
      createdAt: current.createdAt,
      updatedAt: current.updatedAt,
      name: nuw.name !== undefined ? nuw.name : current.name,
      fieldGroups:
        nuw.fieldGroups !== undefined
          ? nuw.fieldGroups
          : current.fieldGroups,
      containsFields: nuw.containsFields !== undefined ? nuw.containsFields : current.containsFields,
      subjectCodes:
        nuw.subjectCodes !== undefined ? nuw.subjectCodes : current.subjectCodes,
      regionCodes: nuw.regionCodes !== undefined ? nuw.regionCodes : current.regionCodes,
      accreditations:
        nuw.accreditations !== undefined ? nuw.accreditations : current.accreditations,
      contractTypes: current.contractTypes,
      // countries: localSavedSearch.countries, //saves searches should not include countries, these are set on the main query
      query: nuw.query !== undefined ? nuw.query : current.query,
      isFrameworkAgreement: nuw.isFrameworkAgreement !== undefined ? undefinedIfFalse(nuw.isFrameworkAgreement) : current.isFrameworkAgreement,
      isShelteredWorkshop: nuw.isShelteredWorkshop !== undefined ? undefinedIfFalse(nuw.isShelteredWorkshop) : current.isShelteredWorkshop,
      isShelteredProgram: nuw.isShelteredProgram !== undefined ? undefinedIfFalse(nuw.isShelteredProgram) : current.isShelteredProgram,
    };
    updateSavedSearchBackend(updated);
    setCurrent(updated);
  };
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const deleteProfileAndUpdateOpportunities = () => {
    deleteSavedSearch(current);
    dispatch(resetSearch());
    setShowConfirmPopup(false);
  };

  const toggleEditMode = () => {
    if (canBeEdited) setEditModeOn(!editModeOn);
  };
  let savedSearchName: string = current.name
    ? current.name
    : TenderSearchUtil.getSampleTenderSearchName(current, t);
  useEffect(() => {
    if (isError && error) dispatch(showErrorPopup(error));
    if (isSuccess && updatedSavedSearch) {
      setCurrent(updatedSavedSearch);
      //update store so opportunities are refreshed
      dispatch(updateIndividualTenderSearchState(updatedSavedSearch));
      dispatch(resetSearch());
    }
  }, [updatedSavedSearch, isError, error]);
  return (
    <>
      {showConfirmPopup && (
        <ConfirmPopup
          title={t("settings.deleteProfileTitle")}
          message={t("settings.deleteProfileMessage")}
          confirm={deleteProfileAndUpdateOpportunities}
          close={() => setShowConfirmPopup(false)}
        />
      )}
      <div className="filter-grid-border-style" data-cy={"user-profile-card"}>
        <div className="icon-text-filter-alignment">
          <h2
            onClick={toggleEditMode}
            style={current.name ? {} : { color: "var(--bg-light-grey)" }}
            title={
              current.name
                ? t("settings.profile_name_placeholder")
                : t("settings.name_this_profile_for_example") + savedSearchName
            }
            data-cy={"user-profile-card-title"}
          >
            {savedSearchName}
          </h2>
          <div className="setting-icon-end-side">
            {canBeEdited && (
              <a onClick={() => setEditModeOn(!editModeOn)}>
                <GearIcon />
              </a>
            )}
            <a data-cy={"remove-user-profile-button"} onClick={() => setShowConfirmPopup(true)}>
              <TrashIcon />
            </a>
          </div>
        </div>
        {!editModeOn && <SavedSearchSummary savedSearch={current} curatedProfile={curatedProfile} />}
        {editModeOn && (
          <SavedSearchDetails
            userUuid={userUuid}
            savedSearch={current}
            updateSavedSearch={updateSavedSearch}
            isLoading={isLoading}
          />
        )}
      </div>
    </>
  );
};

const SavedSearchSummary: React.FC<{ savedSearch: TenderSearch; curatedProfile: boolean }> = ({
  savedSearch,
  curatedProfile,
}) => {
  const { t } = useTranslation();
  let messages: string[] = [];
  let languages = savedSearch.languageIsos ? savedSearch.languageIsos : [];
  let cuesFragment = TenderSearchUtil.getCuesFragment(savedSearch, 3);
  let cues = TenderSearchUtil.getCues([savedSearch]);
  if (curatedProfile) messages.push(t("savedSearchSettings.expertManaged"));
  else {
    if (cues.length > 0) {
        messages.push(
          cues.length +
            " " +
            t("savedSearchSettings.keyWords") +
            " (" +
            languages.join(", ") +
            "): " +
            cuesFragment);
    }
    if (savedSearch.subjectCodes !== undefined) {
      let subjectCodeRoots: string[] = getSubjectCodeBranchRoots(savedSearch.subjectCodes);
      if (subjectCodeRoots.length > 0)
        messages.push(t("savedSearchSettings.subjectCodes") + ": " + subjectCodeRoots.join(", "));
    }
    if (savedSearch.regionCodes !== undefined) {
      let regionCodeRoots: string[] = getRegionCodeBranchRoots(savedSearch.regionCodes);
      if (regionCodeRoots.length > 0)
        messages.push(t("savedSearchSettings.regionCodes") + ": " + regionCodeRoots.join(", "));
    }
    if (savedSearch.accreditations !== undefined) {
      let accreditations: string[] = savedSearch.accreditations.map((a) => CodeUtil.convertCodeWithClassToString(a));
      if (accreditations.length > 0)
        messages.push(t("savedSearchSettings.accreditations") + ": " + accreditations.sort().join(", "));
    }
    if (savedSearch.containsFields !== undefined) {
      let publicationTypes: string[] = SearchFilterUtil.translateContainsFieldsFiltersBack(savedSearch.containsFields).map((a) => t("searchFilters." + a));
      if (publicationTypes.length > 0)
        messages.push(t("savedSearchSettings.formTypes") + ": " + publicationTypes.sort().join(", "));
    }
  }

  return (
    <>
      <div className="filter-grid">
        <div className="filter-grid-items">
          {messages.map((m, i) => (
            <a key={i}>{m}</a>
          ))}
        </div>
      </div>
    </>
  );
};

function getRegionCodeBranchRoots(codes: CodeBranch[]) {
  let regionCodeRoots: string[] = [];
  let sortedCodes: string[] = codes.map((c) => c.code).sort(); //BE,BE1,FR25
  for (const sc of sortedCodes) {
    let rootAlreadyPresent = regionCodeRoots.filter((c) => sc.startsWith(c)).length > 0;
    if (!rootAlreadyPresent) regionCodeRoots.push(sc);
  }
  return regionCodeRoots;
}

function getSubjectCodeBranchRoots(codes: CodeBranch[]): string[] {
  let subjectCodeRoots: string[] = [];
  let sortedCodes: string[] = codes.map((c) => c.code).sort(); //15000000,45100000
  for (const sc of sortedCodes) {
    let root = CpvUtil.getRoot(sc);
    let rootAlreadyPresent = subjectCodeRoots.filter((c) => root.startsWith(c)).length > 0;
    if (!rootAlreadyPresent) subjectCodeRoots.push(root);
  }
  return subjectCodeRoots;
}
