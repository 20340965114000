import React, {useState} from 'react'
import './loginHeader.scss';
import TenderWolfLogo from '../../assets/logo/black-logo-row.svg';
import i18next from "i18next";
import {FlagIcon} from "../flags/flags";
import {Country} from "../../consts/countries";
import {Link} from "react-router-dom";

interface HeaderProps {
    showLanguagesDropdown: Boolean;
}

export const LoginOrSignupHeader: React.FC<HeaderProps> = ({showLanguagesDropdown}) => {
    return (
      <>
        <div>
          <header>
            <div className="container">
              <div className="header-alignment">
                <div className="logo">
                  <a href="https://tenderwolf.com/product">
                    <img src={TenderWolfLogo} alt="TenderWolfLogo"/>
                  </a>
                </div>
                <div className="language-select">{showLanguagesDropdown && <LanguageDropdown></LanguageDropdown>}</div>
              </div>
            </div>
          </header>
        </div>
      </>
    );
}

const LanguageDropdown: React.FC = () => {
    let allLanguages = ["nl", "fr", "en"];
    const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguageIso(allLanguages));
    let dropdownLanguages = getDropdownLanguages(allLanguages.filter(e => e !== currentLanguage));
    let selectedLanguage: DropdownElement = {
        key: currentLanguage,
        value: <LanguageName lg={currentLanguage}/>
    };

    const onLocaleChange: React.MouseEventHandler<HTMLDivElement> = (e) => {
        i18next.changeLanguage(e.currentTarget.id);
        setCurrentLanguage(e.currentTarget.id);
    };

    return <LanguageDropdownWithData unselectedElements={dropdownLanguages}
                              onDropdownChange={onLocaleChange}
                              selectedElement={selectedLanguage}/>
}

function getCurrentLanguageIso(allLanguages: string[]): string {
    let browserLanguage = i18next.language.substring(0, 2);
    return allLanguages.includes(browserLanguage) ? browserLanguage : "en";
}

function getDropdownLanguages(languages: string[]) {
    let elements: DropdownElement[] = []
    for (const lg of languages) {
        elements.push(
            {key: lg, value: <LanguageName lg={lg}/>});
    }
    return elements;
}

interface LanguageNameProps {
    lg: string
}

const LanguageName: React.FC<LanguageNameProps> = ({lg}) => {

    function getCountry(language: string): Country {
        switch (language) {
            case 'nl':
                return Country.NL;
            case 'fr':
                return Country.FR;
            default:
                return Country.UK;
        }
    }

    return <>
        <div>
            <span><FlagIcon country={getCountry(lg)}/></span>
        </div>
        <div>
            <span>{lg.toUpperCase()}</span>
        </div>
    </>
}

interface LanguageDropdownProps {
    unselectedElements: DropdownElement[];
    onDropdownChange: React.MouseEventHandler<HTMLDivElement>;
    selectedElement?: DropdownElement
}

const LanguageDropdownWithData: React.FC<LanguageDropdownProps> = ({
                                                                unselectedElements,
                                                                onDropdownChange,
                                                                selectedElement
                                                            }) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const onDropdownChangeClose: React.MouseEventHandler<HTMLDivElement> = (e) => {
        setDropdownOpen(false);
        onDropdownChange(e);
    };

    return (<>
        <div className='dropdown'>
            <div className='dropdown-design' onMouseDown={() => setDropdownOpen(!dropdownOpen)} tabIndex={1} onBlur={() => setDropdownOpen(false)}>
                {selectedElement && <div className='icon-text-alignment selected-value'
                                         id={selectedElement.key}>{selectedElement.value}</div>}
            </div>
            <div className={dropdownOpen ? 'dropdown dropdown-show' : 'dropdown dropdown-hidden'}>
                <div className='dropdown-design'>
                    {unselectedElements.map((dropdownElement, i) =>
                        <div className='icon-text-alignment' id={dropdownElement.key} onMouseDown={onDropdownChangeClose} key={i}>
                            {dropdownElement.value}
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>)
}

interface DropdownElement {
    key: string;
    value: React.ReactElement;
}
