import React from "react";
import "./banner.scss";

export interface BannerMessage {
  message: string[];
  handleClick: () => void;
}

export const Banner: React.FC<BannerMessage> = ({message, handleClick}) => {
    return <div className={"banner-bottom"} onClick={handleClick}>
        {message.map((message, index) => <span key={index}>{message}</span>)}
    </div>
}


