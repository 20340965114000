import { ResourceKey } from "i18next";

export const Publications: ResourceKey = {
	services: "Diensten",
	supplies: "Leveringen",
	works: "Werken",
	publicationSubtitle: "Publicatie",
	accompanyingDocumentsSubtitle: "Bestek",
	descriptionTitle: "Beschrijving",
	shortContentTitle: "Korte inhoud",
	multipleLotsSubtitle: "Beschrijving percelen",
	singleLotSubtitle: "Beschrijving",
	lotTitle: "Perceel",
	contractAwardsTitle: "Gunningen",
	additionalInformationTitle: "Aanvullende informatie",
	designContestDetailsTitle: "Details",
	bidTitle: "Offerte",
	bidsTitle: "Offertes",
	noBidValue: "Geen openbare offerteprijs",
	companyTitle: "Bedrijf",
	companiesTitle: "Bedrijven",
	participantsTitle: "Deelnemers",
	participantTitle: "Deelnemer",
	winnersTitle: "Winnaars",
	winnerTitle: "Winnaar",
	nonWinnersTitle: "Niet-winnende bedrijven",
	nonWinnerTitle: "Niet-winnend bedrijf",
	noAwardsTitle: "Geen gunningen gevonden",
	noAwardsOrDiscontinuationTitle: "Geen geldige offertes of stopzetting procedure",
	noBidsTitle: "Geen offertes gevonden",
	offersReceivedTitle: "Aantal inschrijvingen",
	resultStatusTitle: "Status",
	decisionReasonTitle: "Reden",
	publicationDateTitle: "Publicatie",
	awardPublicationDateTitle: "Gunning",
	openingReportPublicationDateTitle: "Opening offertes",
	deadlineTitle: "Deadline",
	dateOfContractNoticeTitle: "Datum aankondiging",
	totalValueTitle: "Waarde",
	estimatedTotalValueTitle: "Geraamde totale waarde",
	exactTotalValueTitle: "Totale waarde",
	lowestAndHighestValueTitle: "Laagste en hoogste waarde",
	isFrameworkTitle: "Raamovereenkomst",
	isShelteredWorkshopTitle: "Beschutte werkplaats",
	isShelteredProgramTitle: "Beschut programma",
	contractTypeTitle: "Contracttype",
	procedureTypeTitle: "Procedure",
	shelteredTitle: "Maatwerk",
	frameworkTitle: "Raamovereenkomst",
	contractingAuthorityTitle: "Aanbestedende overheid",
	contactPoint: "Contactpersoon",
	subjectCodesTitle: "Opdrachtcodes (CPV)",
	supplementarySubjectCodesTitle: "Aanvullende opdrachtcodes (CPV)",
	regionCodesTitle: "Regiocode (NUTS)",
	accreditationsTitle: "Erkenningen",
	accreditationClassTitle: "Klasse",
	accreditationCategoryTitle: "Categorie",
	durationTitle: "Geraamde duur",
	awardCriteriaTitle: "Gunningscriteria",
	awardCriteriumTitle: "Gunningscriterium",
	awardCriteriumPriceTitle: "Prijs",
	awardCriteriumQualityTitle: "Kwaliteit",
	awardCriteriumDescriptiveTitle: "Beschrijving",
	selectionCriteriaTitle: "Selectiecriteria",
	selectionCriteriumTitle: "Selectiecriterium",
	selectionCriteriumWeight: "Gewicht",
	conditionsForParticipationTitle: "Voorwaarden voor deelneming",
	financialConditionsTitle: "Economische en financiële draagkracht",
	financialConditionsInformationSubtitle: "Algemeen",
	financialConditionsMinimumSubtitle: "Minimum vereisten",
	technicalConditionsTitle: "Technische en beroepsbekwaamheid",
	technicalConditionsInformationSubtitle: "Algemeen",
	technicalConditionsMinimumSubtitle: "Minimum vereisten",
	performanceConditionsTitle: "Voorwaarden met betrekking tot de opdracht",
	performanceConditionsSubtitle: "Economische en financiële draagkracht",
	corrigendumChangesTitle: "Wijzigingen",
	oldValueTitle: "Oude waarde",
	newValueTitle: "Nieuwe waarde",
	lotNumberTitle: "Perceelnummer",
	sectionTitle: "Afdelingsnummer",
	labelTitle: "Plaats wijziging",
	corrigendumDateTimeChange: "Tijdswijziging",
	corrigendumDateChange: "Datumwijziging",
	corrigendumMainSubjectCodeChange: "Wijziging van opdrachtcodes",
	corrigendumAdditionalSubjectCodesChange: "Wijziging van opdrachtcodes",
	corrigendumTextChange: "Tekstwijziging",
	alreadySelectedTitle: "Reeds geselecteerd",
	cashPrizeTitle: "Geldprijs",
	isBindingTitle: "Bindend",
	willBeAwardedTitle: "Zal toegekend worden",
	detailsPaymentTitle: "Details vergoeding",
	juryTitle: "Jury",
	month: "maanden",
	day: "dagen",
	true: "ja",
	false: "neen",
	filesTitle: "Bestanden",
	externalAddendumUrlTitle: "Aanbestedingsstukken",
	downloadFileTitle: "Download",
	downloadViaArchiveTitle: "Download via volledig project",
	languageTitle: "Taal",
	languagesTitle: "Talen",
	fields: "Zoeken in",
	title: "Titel",
	description: "Beschrijving",
	requirements: "Vereisten",
	addenda: "Bijlagen",
	today: "Vandaag",
	yesterday: "Gisteren",
	tomorrow: "Morgen",
	lastWeek: "Vorige week",
	lastMonth: "Vorige maand",
	nextWeek: "Volgende week",
	nextMonth: "Volgende maand",
	everything: "Alles",
	notYetExpired: "Nog niet verstreken",
	in7Days: "Binnen 7 dagen",
	in14Days: "Binnen 14 dagen",
	in1Month: "Binnen 1 maanden",
	in2Months: "Binnen 2 maanden",
	municipalDecisionTypeTitle: "Type",
	resolutionTitle: "Besluit",

  publicationRadiusTitle: "Europese publicatie",
	yes: "Ja",
	no: "Nee",
	euInstitution: "Internationale organisatie",
	ministry: "Ministerie",
	utilities: "Nutssectoren",
	bodyPublic: "Publiekrechtelijke instelling",
	regionalAuthority: "Regionale instantie",
	other: "Andere",
	regionalAgency: "Regionaal agentschap",
	nationalAgency: "Nationaal agentschap",
	publicationType: "Formuliertype",
	priorInformationNotice: "Vooraankondiging",
	contractNotice: "Aankondiging",
	contractAwardNotice: "Gunning",
	awardModification: "Recificatie van een gunning",
	designContest: "Ontwerpwedstrijd",
	corrigendum: "Rectificatie",
	simplifiedPublication: "Vereenvoudigde publicatie",
	openingReport: "Rapport van opening",
	municipalDecision: "Gemeenteraadsverslag",
	countries: "Landen",
	relevance: "relevantie",
	date: "datum",
	awardDate: "gunning",
	searchLeads: "Shortlist",
	searchSector: "Sector",
	searchCompanies: "Gevolgde bedrijven",
	searchEverything: "Alles",
	showMoreResults: "Toon meer resultaten",
	showFewerResults: "Toon minder resultaten",
	results: "resultaten",
  resultsPerPage: "Resultaten per pagina:",
  sortResultsBy: "Sorteer op:",
  export: "Exporteer",
	noResults: "Geen resultaten",
	searchTermsFound: "Gevonden zoektermen",
	interestingKeywords: "Interessante zoektermen",
	noSearchTermsFound: "geen",
	noLabelsFound: "geen",
	noInfoRefineSearch: "Te veel resultaten om accurate filterinformatie weer te geven",
	searchAddenda: "Zoeken in bijlagen",
	toggleSearchSector: "Zoeken in mijn sector",
	searchAwardCompanyNames: "Zoeken in winnaars",
  searchBidCompanyNames: "Zoeken in deelnemers",
	addLabel: "Voeg label toe",
	opportunityRemoved: "Deze opportuniteit werd verwijderd",
  undo: "Ongedaan maken",
	officialPublication: "Officiële publicatie",
	source: "Bron",
	archive: "Archief",
	ongoing: "Actueel",
	lastChangedOn: "laatste wijziging op ",
	price: "Prijs",
	quality: "Kwaliteit",
	resultStatusCLOSED: "Er is geen winnaar gekozen en de mededinging is gesloten.",
	resultStatusOPEN: "Er is nog geen winnaar gekozen, maar de mededinging loopt nog.",
	resultStatusAWARDED: "Er is ten minste één winnaar gekozen.",
	resultStatusUNKNOWN: "Onbekend",
	decisionReasonALL_REJECTED: "Alle inschrijvingen, verzoeken tot deelname of projecten zijn ingetrokken of niet-ontvankelijk bevonden",
	decisionReasonCHANGE_NEEDED: "Besluit van de koper, wegens gewijzigde behoeften",
	decisionReasonINSUFFICIENT_FUNDS: "Besluit van de koper, wegens ontoereikende middelen",
	decisionReasonNO_TENDERS: "Er zijn geen inschrijvingen, verzoeken tot deelname of projecten ontvangen",
	decisionReasonNO_SIGNED: "De hoogst gerangschikte inschrijver(s) weigerde(n) het contract te ondertekenen",
	decisionReasonONE_ADMISSIBLE: "Er is slechts één ontvankelijk(e) inschrijving, verzoek tot deelname of project ontvangen",
	decisionReasonOTHER: "Andere",
	decisionReasonREVIEW_BODY: "Besluit van een beroepsinstantie of een andere rechterlijke instantie",
	decisionReasonREVIEW_BUYER: "Besluit van de koper, volgend op een verzoek van een inschrijver tot herziening van de gunning",
	decisionReasonTECHNICAL_PROCEDURAL_ERROR: "Besluit van de koper, niet volgend op een verzoek van een inschrijver tot herziening van de gunning, vanwege technische of procedurele fouten",
	decisionReasonUNKNOWN: "Onbekend",
	chooseADate: "Kies een start- en een einddatum",
	filter: "Filter",

};
