import { ResourceKey } from "i18next";

export const SearchFilterTags: ResourceKey = {
  RESET_FILTERS: "Effacer tous les filtres",
  FIELD_GROUP_TITLE: "Champ: Titre",
  FIELD_GROUP_DESCRIPTION: "Champ: Description",
  FIELD_GROUP_SPECIFICATIONS: "Champ: Description Spécifications",
  FIELD_GROUP_ADDENDA: "Chercher addenda: OUI",
  FIELD_GROUP_AWARD_COMPANY_NAMES: "chercher gagnants: OUI",
  FIELD_GROUP_BID_COMPANY_NAMES: "chercher participants: OUI",

  PUBLICATION_DATE_EVERYTHING: "",
  PUBLICATION_DATE_YESTERDAY: "",
  PUBLICATION_DATE_LAST_WEEK: "",
  PUBLICATION_DATE_LAST_MONTH: "",
  PUBLICATION_DATE_LAST_TWO_MONTHS: "",
  PUBLICATION_DATE_LAST_YEAR: "",
  PUBLICATION_DATE_CUSTOM: "",

  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR: "",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM: "",

  DEADLINE_EVERYTHING: "",
  DEADLINE_NOT_YET_EXPIRED: "",
  DEADLINE_IN_7_DAYS: "",
  DEADLINE_IN_14_DAYS: "",
  DEADLINE_IN_1_MONTH: "",
  DEADLINE_IN_2_MONTHS: "",
  DEADLINE_CUSTOM: "",
  DEADLINE_THIS_YEAR: "",
  DEADLINE_PREVIOUS_YEAR: "",
  DEADLINE_TWO_YEARS_AGO: "",
  COUNTRY_PREFIX: "Pays:",
  LANGUAGE_PREFIX: "Langue:",
  HAS_PRIOR_INFORMATION_NOTICE: "Type de formulaire: Préinformation",
  HAS_CONTRACT_NOTICE: "Type de formulaire: Avis de marché",
  HAS_CONTRACT_AWARD_NOTICE: "Type de formulaire: Attribution",
  HAS_DESIGN_CONTEST: "Type de formulaire: Modification de l'attribution",
  HAS_CORRIGENDA: "Type de formulaire: Concours",
  HAS_AWARD_MODIFICATIONS: "Type de formulaire: Rectification",
  HAS_OPENING_REPORT: "Type de formulaire: PV d'ouverture",
  HAS_MUNICIPAL_DECISION: "Type de formulaire: Rapport du Conseil municipal",
  HAS_ADDENDA: "Addenda: oui",
  HAS_AWARDS: "Attribué: oui",

  DOES_NOT_HAVE_PRIOR_INFORMATION_NOTICE: "",
  DOES_NOT_HAVE_CONTRACT_NOTICE: "",
  DOES_NOT_HAVE_CONTRACT_AWARD_NOTICE: "",
  DOES_NOT_HAVE_DESIGN_CONTEST: "",
  DOES_NOT_HAVE_CORRIGENDA: "",
  DOES_NOT_HAVE_AWARD_MODIFICATIONS: "",
  DOES_NOT_HAVE_OPENING_REPORT: "",
  DOES_NOT_HAVE_ADDENDA: "Addenda: non",
  CONTRACT_TYPE_SERVICES: "Type de contrat: Services",
  CONTRACT_TYPE_WORKS: "Type de contrat: Travaux Travaux",
  CONTRACT_TYPE_SUPPLIES: "Type de contrat: Travaux Fournitures",
  CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION: "Autorité contractante: Organisation internationale",
  CONTRACTING_AUTHORITY_TYPE_MINISTRY: "Autorité contractante: Ministère",
  CONTRACTING_AUTHORITY_TYPE_UTILITIES: "Autorité contractante: Services publics",
  CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC: "Autorité contractante: Organisme de droit public",
  CONTRACTING_AUTHORITY_TYPE_RA: "Autorité contractante: Autorité régionale",
  CONTRACTING_AUTHORITY_TYPE_LA: "Autorité contractante: Autorité locale",
  CONTRACTING_AUTHORITY_TYPE_CGA: "Autorité contractante: Agence du gouvernement central",
  CONTRACTING_AUTHORITY_TYPE_DEF_CONT: "Autorité contractante: Contractant du secteur de la défense",
  CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT: "Autorité contractante: Entreprise publique",
  CONTRACTING_AUTHORITY_TYPE_REGIONAL_AGENCY: "Autorité contractante: Agence régionale",
  CONTRACTING_AUTHORITY_TYPE_NATIONAL_AGENCY: "Pouvoir adjudicateur: Agence nationale",
  CONTRACTING_AUTHORITY_TYPE_OTHER: "Autorité contractante: Autre",
  PUBLICATION_RADIUS_EUROPEAN_UNION: "Publication européenne: Oui",
  PUBLICATION_RADIUS_NATIONAL: "Publication européenne: Non",

  TENDER_UUID_FILTER_LEADS: "",
  TENDER_UUID_FILTER_FOLLOWED_TENDERS: "",
  TENDER_UUID_FILTER_OPPORTUNITIES: "",
  PROCEDURE_TYPE_OPEN: "Procédure: Ouverte",
  PROCEDURE_TYPE_RESTRICTED: "Procédure: Restreinte",
  PROCEDURE_TYPE_COMPETITIVE_DIALOGUE: "Procédure: Dialogue compétitive",
  PROCEDURE_TYPE_INNOVATION_PARTNERSHIP: "Procédure: Partenariat d'innovation",
  PROCEDURE_TYPE_NEGOTIATED: "Procédure: Négociée",
  PROCEDURE_TYPE_OTHER: "Procédure: Autre",

  IS_FRAMEWORK_AGREEMENT_YES: "Accord-cadre",
  IS_FRAMEWORK_AGREEMENT_NO: "Accord-cadre non",
  IS_SHELTERED_WORKSHOP: "Réservé aux ateliers protégés",
  IS_SHELTERED_PROGRAM: "Programmes d'emploi protégé",

  until: "à",
  label: "Label",
  notYetExpired: "pas encore expiré",
  limited_results_message_part_one: "Votre plan tarifaire actuel ne vous permet d'afficher que ",
  limited_results_message_part_two: " résultats. Changez de plan pour afficher et rechercher les résultats restants.",
  hints_date_message_part_one : "Vous effectuez une recherche dans la période allant de ",
  hints_date_message_part_two : " à ",
  hints_date_message_part_three : ". Modifiez la date dans la barre latérale pour élargir votre recherche.",
  trial_message_part_one: "Vos paramètres n'ont pas encore été optimisés par l'un de nos experts. ",
  trial_message_part_two: "Contactez-nous",
  trial_message_part_three: " pour une mise au point gratuite ",
  trial_message_part_four: "ou jetez un coup d'œil sur ",
  trial_message_part_five: "Démarrer avec TenderWolf",
  search: "Rechercher",
  similarTenderUuid: "Similaire à"
};
