import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useTranslation } from "react-i18next";
import { SearchFilter } from "../../../../consts/SearchFilter";
import {
    resetDeadlineFilter, resetOpeningReportOrAwardDateFilter,
    resetPublicationDateFilter,
    SearchFilterState,
    updateSearchFilters
} from "../../../../hooks/slices/tenderSearchSlice";
import { SearchFilterUtil } from "../../../../utils/searchFilters";
import { QueryFilterButton } from "./QueryFilterButton";
import { DateFilterButton } from "./DateFilterButton";
import { DateUtil } from "../../../../utils/date";
import { SearchFilterButton, SearchFilterButtonSkeleton } from "./SearchFilterButton";
import { CodeBranchFilterButtons, CodeWithClassFilterButtons } from "./CodeFilterButtons";
import { LabelFilterButtons } from "./LabelFilterButtons";
import { ResetAllFiltersButton } from "./ResetAllFiltersButton";
import { SearchResultsHeaderProps } from "./SearchResultsHeader";
import {CompanyFilterButtons} from "./CompanyFilterButtons";
import {UserInteraction} from "../../../../hooks/slices/userSlice";
import {RaiseUser} from "../../../../hooks/raiseUser";
import {SimilarTenderFilterButton} from "./SimilarTenderFilterButton";

export const SearchFilterButtons: React.FC<SearchResultsHeaderProps> = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
    let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    const searchFilters = useAppSelector((state) => state.tenderSearch.searchFilters);
  const removeSearchFilter = (searchFilter: SearchFilter) => {
    dispatch(updateSearchFilters({ selectedFilters: searchFilters.selectedFilters.filter((s) => s !== searchFilter), userInteractions: userInteractions }));
  };
  let hiddenFilters: SearchFilter[] = props.searchConfiguration.info.hiddenFilters
    ? props.searchConfiguration.info.hiddenFilters
    : [];
  let lockedFilters: SearchFilter[] = props.searchConfiguration.info.lockedFilters
    ? props.searchConfiguration.info.lockedFilters
    : [];
  let hiddenOrLockedFilters: SearchFilter[] = hiddenFilters.concat(lockedFilters);
  let shownGenericSearchFilters: SearchFilter[] = Array.from(
    new Set(searchFilters.selectedFilters.filter((f) => !hiddenOrLockedFilters.includes(f)))
  );
  let numberOfFiltersShown: number = countShownFilters(searchFilters, shownGenericSearchFilters);
  const resetQuery = () => {
    dispatch(updateSearchFilters({ query: "", userInteractions: userInteractions }));
  };
  let currentDateFilter = SearchFilterUtil.findPublicationDateFilters(searchFilters.selectedFilters);
  let hideDateFilter: boolean =
    currentDateFilter.filter((f) => props.searchConfiguration.info.hiddenFilters?.includes(f)).length > 0;
  if (numberOfFiltersShown === 0) return <></>;
  return (
    <>
      {props.searchConfiguration.info.showFilterButtons && (
        <div className="search-filter-button-alignment">
            {searchFilters.similarTender && (<SimilarTenderFilterButton similarTender={searchFilters.similarTender}/>)}
            <QueryFilterButton query={searchFilters.query} resetQuery={resetQuery} />
          {!hideDateFilter && (
            <DateFilterButton
              label={t("publications.publicationDateTitle")}
              start={searchFilters.startPublicationDate}
              end={searchFilters.endPublicationDate}
              canRemove={true}
              removeDateFilters={() => dispatch(resetPublicationDateFilter())}
            />
          )}
            <DateFilterButton
              label={t("publications.deadlineTitle")}
              start={DateUtil.convertDateTimeStringToDateString(searchFilters.startDeadlineDate)}
              end={DateUtil.convertDateTimeStringToDateString(searchFilters.endDeadlineDate)}
              canRemove={true}
              removeDateFilters={() => dispatch(resetDeadlineFilter())}
            />
            <DateFilterButton
                label={t("publications.awardPublicationDateTitle")}
                start={DateUtil.convertDateTimeStringToDateString(searchFilters.awardOrOpeningReportPublicationStartDate)}
                end={DateUtil.convertDateTimeStringToDateString(searchFilters.awardOrOpeningReportPublicationEndDate)}
                canRemove={true}
                removeDateFilters={() => dispatch(resetOpeningReportOrAwardDateFilter())}
            />
          {shownGenericSearchFilters.sort().map((filter, i) => (
            <SearchFilterButton
              key={i}
              searchFilter={filter}
              removeSearchFilter={removeSearchFilter}
              canBeRemoved={true}
              searchResultsConfiguration={props.searchConfiguration.info}
            />
          ))}
          {searchFilters.subjectCodes && <CodeBranchFilterButtons codes={searchFilters.subjectCodes} />}
          {searchFilters.regionCodes && <CodeBranchFilterButtons codes={searchFilters.regionCodes} />}
          {searchFilters.accreditations && <CodeWithClassFilterButtons codes={searchFilters.accreditations} />}
          {searchFilters.labelFilters && (
            <LabelFilterButtons labelFilters={searchFilters.labelFilters} />
          )}
          {searchFilters.vatNumberFilters && (
                <CompanyFilterButtons vatNumberFilters={searchFilters.vatNumberFilters} />
           )}
          {numberOfFiltersShown > 1 && (
            <ResetAllFiltersButton
              selectedFilters={searchFilters.selectedFilters}
              hiddenOrLockedFilters={hiddenOrLockedFilters}
            />
          )}
        </div>
      )}
    </>
  );
};

function countShownFilters(searchFilters: SearchFilterState, genericFilters: SearchFilter[]) {
    let count = genericFilters.length;
    if (searchFilters.query !== "") count++;
    if (searchFilters.subjectCodes != null && searchFilters.subjectCodes.length > 0) count+=searchFilters.subjectCodes.length;
    if (searchFilters.regionCodes != null && searchFilters.regionCodes.length > 0) count+=searchFilters.regionCodes.length;
    if (searchFilters.accreditations != null && searchFilters.accreditations.length > 0) count+=searchFilters.accreditations.length;
    if (searchFilters.labelFilters != null && searchFilters.labelFilters.length > 0) count+=searchFilters.labelFilters.length;
    if (searchFilters.similarTender != null ) count+=1;
    return count;
}

export const SearchFilterButtonsSkeleton = () => {
    return <div className="search-filter-button-alignment"><SearchFilterButtonSkeleton/></div>;
};