import { ResourceKey } from "i18next";

export const Publications: ResourceKey = {
	services: "Services",
	supplies: "Fournitures",
	works: "Travaux",
	publicationSubtitle: "Publication",
	accompanyingDocumentsSubtitle: "Documents d'accompagnement",
	descriptionTitle: "Description",
	shortContentTitle: "Contenu bref",
	multipleLotsSubtitle: "Description lots",
	singleLotSubtitle: "Description",
	lotTitle: "Lot",
	contractAwardsTitle: "Prix",
	additionalInformationTitle: "Informations complémentaires",
	designContestDetailsTitle: "Détails",
	bidTitle: "Offre",
	bidsTitle: "Offres",
	noBidValue: "Pas de prix de l'offre publique",
	companyTitle: "Société",
	companiesTitle: "Entreprises",
	participantsTitle: "Participants",
	participantTitle: "Participant",
	winnersTitle: "Gagnants",
	winnerTitle: "Gagnant",
	nonWinnersTitle: "Entreprises non gagnantes",
	nonWinnerTitle: "Entreprise non gagnante",
	noAwardsTitle: "Aucune attribution trouvée",
	noAwardsOrDiscontinuationTitle: "Pas d'offres valables ou cessation de procédure",
	noBidsTitle: "Aucune offre trouvée",
	offersReceivedTitle: "Offres reçues",
	resultStatusTitle: "Statut",
	decisionReasonTitle: "Raison",
	publicationDateTitle: "Publication",
	awardPublicationDateTitle: "Attribution",
	openingReportPublicationDateTitle: "Ouverture d'offres",
	deadlineTitle: "Date limite",
	dateOfContractNoticeTitle: "Date de l'avis",
	totalValueTitle: "Valeur",
	estimatedTotalValueTitle: "Estimation de la valeur totale",
	exactTotalValueTitle: "Valeur totale",
	lowestAndHighestValueTitle: "Valeur la plus basse et la plus élevée",
	isFrameworkTitle: "Accord-cadre",
	isShelteredWorkshopTitle: "Atelier protégé",
	isShelteredProgramTitle: "Programme protégé",
	contractTypeTitle: "Type de contrat",
	procedureTypeTitle: "Procédure",
	shelteredTitle: "Atelier protégé",
	frameworkTitle: "Accord-cadre",
	contractingAuthorityTitle: "Pouvoirs adjudicateurs",
	contactPoint: "Contact",
	subjectCodesTitle: "Codes sujets (CPV)",
	supplementarySubjectCodesTitle: "Codes sujets supplémentaires (CPV)",
	regionCodesTitle: "Codes de région (NUTS)",
	accreditationsTitle: "Accréditations",
	accreditationClassTitle: "Classe",
	accreditationCategoryTitle: "Catégorie",
	durationTitle: "Durée estimée",
	awardCriteriaTitle: "Critères d'attribution",
	awardCriteriumTitle: "Critères d'attribution",
	awardCriteriumPriceTitle: "Prix",
	awardCriteriumQualityTitle: "Qualité",
	awardCriteriumDescriptiveTitle: "Description",
	selectionCriteriaTitle: "Conditions de selection",
	selectionCriteriumTitle: "Condition de selection",
	selectionCriteriumWeight: "Poids",
	conditionsForParticipationTitle: "Conditions de participation",
	financialConditionsTitle: "Capacité économique et financière",
	financialConditionsInformationSubtitle: "Généralités",
	financialConditionsMinimumSubtitle: "Au minimum",
	technicalConditionsTitle: "Compétences techniques et professionnelles",
	technicalConditionsInformationSubtitle: "Généralités",
	technicalConditionsMinimumSubtitle: "Au minimum",
	performanceConditionsTitle: "Conditions d'exécution",
	performanceConditionsSubtitle: "Capacité économique et financière",
	corrigendumChangesTitle: "Changements",
	oldValueTitle: "Ancienne valeur",
	newValueTitle: "Nouvelle valeur",
	lotNumberTitle: "Numéro de lot",
	sectionTitle: "Section",
	labelTitle: "Label",
	corrigendumDateChange: "Modification d'heure",
	corrigendumDateTimeChange: "Modification de date",
	corrigendumMainSubjectCodeChange: "Modification de codes sujet",
	corrigendumAdditionalSubjectCodesChange: "Modification de codes sujet",
	corrigendumTextChange: "Modification du texte",
	alreadySelectedTitle: "Déjà sélectionné",
	cashPrizeTitle: "Prix en argent",
	isBindingTitle: "Est contraignant",
	willBeAwardedTitle: "Seront attribués",
	detailsPaymentTitle: "Détails du paiement",
	juryTitle: "Jury",
	month: "mois",
	day: "jours",
	true: "oui",
	false: "non",
	filesTitle: "Dossiers",
	externalAddendumUrlTitle: "Documents d’appel d’offres",
    downloadFileTitle: "Télécharger",
	downloadViaArchiveTitle: "Télécharger via le projet complet",
	languageTitle: "Langue",
	languagesTitle: "Langues",
	fields: "Champs",
	title: "Titre",
	description: "Description",
	requirements: "Conditions",
	addenda: "Addenda",
	today: "Aujourd'hui",
	yesterday: "Hier",
	tomorrow: "Demain",
	lastWeek: "Semaine dernière",
	lastMonth: "Mois dernier",
	nextWeek: "Semaine prochaine",
	nextMonth: "Mois prochain",
	everything: "Tout",
	notYetExpired: "Pas encore expiré",
	in7Days: "Dans 7 jours",
	in14Days: "Dans 14 jours",
	in1Month: "Dans 1 mois",
	in2Months: "Dans 2 mois",
	municipalDecisionTypeTitle: "Type",
	resolutionTitle: "Résolution",

    publicationRadiusTitle: "Publication européenne",
	yes: "Oui",
	no: "Non",
	ok: "Ok",
	euInstitution: "Organisation internationale",
	ministry: "Ministère",
	utilities: "Secteurs spéciaux",
	bodyPublic: "Organisme de droit public",
	regionalAuthority: "Autorité régionale",
	other: "Autre",
	regionalAgency: "Agence régionale",
	nationalAgency: "Agence nationale",
	publicationType: "Type de formulaire",
	priorInformationNotice: "Préinformation",
	contractNotice: "Avis de marché",
	contractAwardNotice: "Attribution",
	awardModification: "Modification de l'attribution",
	designContest: "Concours",
	corrigendum: "Rectification",
	openingReport: "PV d'ouverture",
	municipalDecision: "Rapport du Conseil municipal",
	simplifiedPublication: "Publication simplifiée",
	countries: "Pays",
	relevance: "relevance",
	date: "date",
	awardDate: "attribution",
	searchLeads: "Présélection",
	searchSector: "Secteur",
	searchCompanies: "Entreprises suivies",
	searchEverything: "Tout",
	showMoreResults: "Présenter plus de résultats",
	showFewerResults: "Présenter moins de résultats",
	results: "résultats",
	noResults: "Aucun résultat",
    resultsPerPage: "Résultats par page:",
    sortResultsBy: "Trier par:",
    export: "Exporter",
	searchTermsFound: "Mots clés trouvés",
	interestingKeywords: "Mots clés intéressants",
	noSearchTermsFound: "aucun",
	noLabelsFound: "aucun",
	noInfoRefineSearch: "Trop de résultats pour afficher des informations de filtrage précises",
	searchAddenda: "Recherche dans addenda",
	toggleSearchSector: "Chercher dans mon secteur",
    searchAwardCompanyNames: "Recherche dans gagnants",
    searchBidCompanyNames: "Recherche dans participants",
	addLabel: "Ajoutez label",
	opportunityRemoved: "Cette opportunité a été supprimée",
    undo: "Annuler",
	officialPublication: "Publication officielle",
	source: "Source",
	archive: "Archive",
	ongoing: "Actuel",
	lastChangedOn: "dernière modification le",
	price: "Prix",
	quality: "Qualité",
	resultStatusCLOSED: "Aucun lauréat n’a été choisi et la mise en concurrence est clos.",
	resultStatusOPEN: "Le lauréat n’a pas encore été choisi, mais la mise en concurrence est toujours en cours.",
	resultStatusAWARDED: "Au moins un lauréat a été choisi.",
	resultStatusUNKNOWN: "Inconnu",
	decisionReasonALL_REJECTED: "Toutes les offres, toutes les demandes de participation ou tous les projets ont été retiré(e)s ou jugé(e)s irrecevables",
	decisionReasonCHANGE_NEEDED: "Décision de l’acheteur, en raison d’un changement des besoins",
	decisionReasonINSUFFICIENT_FUNDS: "Décision de l’acheteur, faute de fonds suffisants",
	decisionReasonNO_TENDERS: "Aucune offre, aucune demande de participation, ni aucun projet n’a été reçu",
	decisionReasonNO_SIGNED: "Le(s) soumissionnaire(s) le(s) mieux classé(s) a (ont) refusé de signer le marché",
	decisionReasonONE_ADMISSIBLE: "Une seule offre, une seule demande de participation ou un seul projet recevable a été reçu(e)",
	decisionReasonOTHER: "Autre",
	decisionReasonREVIEW_BODY: "Décision d’une instance chargée des procédures de recours ou d’une autre instance judiciaire",
	decisionReasonREVIEW_BUYER: "Décision de l’acheteur consécutive à une demande de réexamen de l’attribution par un soumissionnaire",
	decisionReasonTECHNICAL_PROCEDURAL_ERROR: "Décision de l’acheteur, non consécutive à une demande de réexamen de l’attribution par un soumissionnaire, en raison d’erreurs techniques ou de procédure",
	decisionReasonUNKNOWN: "Inconnu",
	chooseADate: "Choisissez une date de début et de fin",
	filter: "Filtrer",
};
